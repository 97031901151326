import React, { useState, useEffect } from "react";
import { useMedia } from "use-media";
import Layout from "../../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { OnScrollPopup } from "../hr-chatbot";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";

import "react-accessible-accordion/dist/fancy-example.css";
import { redirectToWithUtm } from "../../../components/Header";
const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");

const BlogHeader = require("../../../assets/img/blogs/header_blog_TeamsvsSlack.png.webp");
const Section1 = require("../../../assets/img/blogs/pricing_teams_vs_slack.png.webp");
const Section2 = require("../../../assets/img/blogs/bot_teams_vs_slack.jpg.webp");
const Section3 = require("../../../assets/img/blogs/video_con_teams_vs_slack.png.webp");
const Section4 = require("../../../assets/img/blogs/winner_teams_vs_slack.jpeg.webp");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Microsoft Teams vs Slack: Battle of the Collaboration Powerhouses"
        description="Microsoft teams vs Slack! A battle of David and Goliath to be the best collaboration hub. But it doesn’t have to be a struggle for you."
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={BlogHeader}
        schema={ldSchema}
      />
      <section className="market_main_wrapper">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header ">
                            Microsoft Teams vs. Slack: Battle of the
                            Collaboration Powerhouses
                          </h1>
                        </div>
                      </div>
                      <section className="table_contents_wrapper">
                        <h3>Table of contents</h3>
                        <div className="table_contents_link">
                          <AnchorLink offset={180} href="#section1">
                            1. Similarities
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section2">
                            2. Pricing
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section3">
                            3. Mobile vs Desktop version
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section4">
                            4. Difference between Microsoft teams vs Slack
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section5">
                            5. Microsoft teams vs Slack bots
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section6">
                            6. Video conferencing
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section7">
                            7. Upcoming features
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section8">
                            8. Data protection
                          </AnchorLink>
                        </div>
                      </section>
                      <img src={BlogHeader} />
                      <div className=" mt-5 market_wrapper_page">
                        <p class="font-section-normal-text line-height-2">
                          Before we go on to what features distinguish Teams
                          from Slack and which collaboration hub you should use
                          for your business’ internal/external communications,
                          consider this:
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          Microsoft is a Behemoth. Slack Technologies — Not so
                          much. Though Microsoft Teams is the new kid on the
                          block, things are already looking like{" "}
                          <a href="https://slack.com/intl/en-in/blog/news/dear-microsoft">
                            David and Goliath
                          </a>{" "}
                          between the two. Why? Because Microsoft Teams already
                          has around{" "}
                          <a href="https://www.businessinsider.in/tech/enterprise/news/microsoft-teams-just-hit-20-million-daily-active-users-beating-its-rival-slack-once-again/articleshow/72129973.cms">
                            20 million
                          </a>{" "}
                          daily active users whereas Slack has only{" "}
                          <a href="https://www.cnbc.com/2019/10/10/slack-says-it-crossed-12-million-daily-active-users.html">
                            12 million.
                          </a>{" "}
                          I’m not kidding, FYI.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          And if you’re wondering which one to go for, think
                          again. Because choosing between the two is like seeing{" "}
                          <a href="https://en.wikipedia.org/wiki/The_dress">
                            The dress
                          </a>{" "}
                          photo. The color of the dress depends on the viewer.{" "}
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          But do not fret; we got you covered. Let’s see some
                          similarities and differences between Microsoft Teams
                          and Slack so that you can decide for yourself which
                          one serves your business’ needs well.
                        </p>
                      </div>

                      <NocodeWrapper />

                      <div id="section1">
                        <h2 className="font-section-sub-header-small-bold">
                          Similarities
                        </h2>
                        <h6 className="font-section-sub-header-small">
                          Search Bar
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Both Microsoft Teams, as well as Slack’s search bar,
                          lets you search for messages across your conversations
                          as well as find shared documents. With Slack, if you
                          have a
                          <a href="https://workativ.com/conversational-ai-platform/google-drive-chatbot">
                            {" "}
                            Google Drive Integration,
                          </a>{" "}
                          you can search for phrases inside a document and the
                          document will show up under search results. Likewise,
                          for Microsoft Teams, with the{" "}
                          <a href="https://workativ.com/conversational-ai-platform/office-365-chatbot">
                            Office 365 integration,{" "}
                          </a>
                          of course.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          But when it comes to messaging history, Teams offers
                          unlimited searchable message history for all tiers
                          whereas Slack supports only 10k searchable message
                          history on free and unlimited on paid. Teams-1
                          Slack-0.
                        </p>

                        <h6 className="font-section-sub-header-small">
                          Quick Commands
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          With Slack, you simply type a slash “/” into the
                          message bar, and a list of quick commands appears.
                          With Teams, you can type either “@” or “/” into the
                          search bar and choose from the list of commands. With
                          these helpful, quick commands, you can easily take a
                          shortcut for specific actions within the tools, such
                          as changing your status or adding a user to a team.
                          Simple yet effective.
                        </p>
                        <h6 className="font-section-sub-header-small">
                          Conversational Threads
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Slack and Microsoft Teams offer similar conversation
                          threads for messages, including plain text and
                          tagging. You can start threads in private
                          conversations as well as in group conversations in
                          both.
                        </p>
                      </div>

                      <div id="section2">
                        <h6 className="font-section-sub-header-small">
                          Pricing
                        </h6>
                        <img loading="lazy" className="mb-3" src={Section1} />
                        <p class="font-section-normal-text line-height-2">
                          I’ll let you compare the pricing for both the
                          collaboration hubs so that you’ll get an idea of where
                          your business’ budget lies.
                        </p>

                        <p class="font-section-normal-text ">Teams</p>

                        <p class="font-section-small-text ">Free</p>

                        <p class="font-section-small-text ">
                          Office 365 Business Essentials
                        </p>

                        <p class="font-section-small-text ">
                          $5.00 user/month (annual commitment)
                        </p>

                        <p class="font-section-small-text ">
                          Office 365 Business Premium
                        </p>

                        <p class="font-section-small-text line-height-2">
                          $12.50 user/month (annual commitment)
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Slack
                        </p>

                        <p class="font-section-small-text-medium line-height-2">
                          Free
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          With 10k searchable messages, 10 apps and
                          integrations, 1-to-1 video calls, and two-factor
                          authentication, the free version gives your team
                          access to Slack’s basic features.
                        </p>
                        <p class="font-section-small-text-medium line-height-2">
                          Standard
                        </p>

                        <p class="font-section-small-text line-height-2">
                          For small and medium-sized businesses
                        </p>

                        <p class="font-section-small-text line-height-2">
                          $2.67 USD
                        </p>
                        <p class="font-section-small-text line-height-2">
                          per person, per month, when billed yearly
                        </p>
                        <p class="font-section-small-text line-height-2">
                          $3.20 USD/person, per month, when billed monthly
                        </p>
                        <p class="font-section-small-text-medium line-height-2">
                          Top features:
                        </p>

                        <p class="font-section-small-text line-height-2">
                          Unlimited message archive
                        </p>

                        <p class="font-section-small-text line-height-2">
                          Unlimited apps
                        </p>
                        <p class="font-section-small-text line-height-2">
                          Group calls with screen sharing
                        </p>
                        <p class="font-section-small-text line-height-2">
                          Guest accounts & shared channels
                        </p>
                        <p class="font-section-small-text-medium line-height-2">
                          Plus
                        </p>

                        <p class="font-section-small-text line-height-2">
                          For larger businesses or those seeking advanced
                          administration tools
                        </p>

                        <p class="font-section-small-text line-height-2">
                          $5 USD
                        </p>
                        <p class="font-section-small-text line-height-2">
                          per person, per month, when billed yearly
                        </p>
                        <p class="font-section-small-text line-height-2">
                          $6 USD/person, per month, when billed monthly
                        </p>
                        <p class="font-section-small-text-medium line-height-2">
                          Top features:
                        </p>

                        <p class="font-section-small-text line-height-2">
                          99.99% guaranteed uptime SLA
                        </p>

                        <p class="font-section-small-text line-height-2">
                          User provisioning and de-provisioning
                        </p>
                        <p class="font-section-small-text line-height-2">
                          SAML-based single sign-on (SSO)
                        </p>
                        <p class="font-section-small-text line-height-2">
                          Corporate exports for all messages
                        </p>

                        <p class="font-section-small-text-medium line-height-2">
                          Enterprise Grid
                        </p>

                        <p class="font-section-small-text line-height-2">
                          For very large businesses or those in highly regulated
                          industries
                        </p>

                        <p class="font-section-small-text line-height-2">
                          To get a price estimate for Enterprise Grid, please
                          contact Slack’s Sales team
                        </p>
                        <p class="font-section-small-text-medium line-height-2">
                          Top features:
                        </p>

                        <p class="font-section-small-text line-height-2">
                          Unlimited workspaces
                        </p>

                        <p class="font-section-small-text line-height-2">
                          Support data loss prevention (DLP), e-Discovery and
                          offline backup providers
                        </p>
                        <p class="font-section-small-text line-height-2">
                          Designated customer success teams
                        </p>
                        <p class="font-section-small-text line-height-2">
                          HIPAA-compliant message and file collaboration
                        </p>
                      </div>

                      <div id="section3">
                        <h6 className="font-section-sub-header-small-bold">
                          Mobile and Desktop Versions
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Both apps have mobile and desktop versions. The main
                          difference with their mobile device versions is that
                          Microsoft provides a cloud-based service called{" "}
                          <a href="https://www.microsoft.com/en-us/security/business/microsoft-endpoint-manager">
                            Intune
                          </a>{" "}
                          that allows you to manage your organization’s mobile
                          devices. This app allows you to ensure corporate data
                          is protected as it gives you control over your
                          workforce’s mobile devices and mobile apps.
                        </p>
                      </div>

                      <div id="section4">
                        <h6 className="font-section-sub-header-small-bold">
                          Differences between Microsoft teams vs Slack
                        </h6>
                        <h6 className="font-section-sub-header-small">
                          Deployment
                        </h6>

                        <p class="font-section-normal-text">
                          This is one area where Slack has a clear advantage
                          over Teams. Slack is easy to sign up for and to invite
                          other users to join. Depending on your users, this
                          deployment can take just a few minutes. On the other
                          hand, Teams has more steps to sign up and add team
                          members. Since Teams is built for larger enterprises,
                          its deployment process requires more effort and time.
                          In deploying Teams, it is more effective to have
                          administrators take control of this process. Teams-1
                          Slack-1.
                        </p>
                        <h6 className="font-section-sub-header-small">UX </h6>
                        <p class="font-section-normal-text line-height-2">
                          As for customizing the message interface, Slack offers
                          a better variety. You can switch between light and
                          dark themes to change the overall appearance. For a
                          more customized feel, you can switch the sidebar
                          colors between 10 options, such as Dagobah and
                          Nocturne, that complement your overall theme.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Meanwhile, Microsoft’s client merely swaps between
                          three general themes: Light, dark, and high contrast.
                          There are no specific color options despite the
                          client’s Windows-based roots. Teams-1 Slack-2.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          But Teams comes with a convenient tab-based structure,
                          making it easy to access the apps and services
                          integrated with Teams → Teams-2 Slack-2.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Both support GIFs, though Teams simply provides a more
                          robust way to fully convey your messages. For
                          instance, to insert a GIF into your Slack message, you
                          must enter a command, like “/giphy image name.”
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          In Teams, you’ll find a toolbar at the bottom
                          providing access to a library of GIFs and stickers.
                          Even more, click the Stickers button to create a
                          customized meme. Slack does offer integration with
                          Bitmoji and similar services, but it isn’t native and
                          easy to access as seen with Teams.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          The Microsoft Office team has also revived
                          <a href="https://www.techrepublic.com/article/microsoft-reanimates-corpse-of-maligned-office-assistant-clippy-to-help-teams-compete-with-slack/">
                            {" "}
                            Clippy
                          </a>{" "}
                          as an app to provide animated Clippy stickers in chats
                          in Microsoft Teams. Headline? Relive the 90s
                          nostalgia.
                        </p>
                        <h6 className="font-section-sub-header-small">
                          Storage
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Let’s compare the storage limits for the different
                          tiers for both Slack and Teams.
                        </p>
                        <p class="font-section-normal-text-medium">Slack </p>
                        <p class="font-section-normal-text line-height-2">
                          5GB total on free
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          10GB per user on mid-tier
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          20GB per user on the highest tier
                        </p>
                        <p class="font-section-normal-text-medium">Teams </p>
                        <p class="font-section-normal-text line-height-2">
                          2GB per user + 10GB shared on free
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          1TB per user on paid tiers
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Teams-3 Slack-2.
                        </p>
                        <h6 className="font-section-sub-header-small">
                          App Integration
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          For third party integrations, Microsoft Teams will be
                          the better choice for most. You get 250+ integrated
                          apps for all pricing tiers, including the free version
                          of Microsoft Teams. More importantly, Microsoft Teams
                          is perfectly integrated with Office 365.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          It becomes very easy to collaborate within documents
                          and files through the Office 365 integration, and you
                          can even use Microsoft Team’s search bar to search
                          within documents themselves. This integration beats
                          anything Slack can offer in this regard.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Slack can have up to 10 app integrations for free, and
                          up to 1134 if you pay. For most people though, 10 is
                          more than enough. 1134 is overkill.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          So, it really comes down to whether your team would
                          find benefit in the Office 365 integration or not.
                          Teams-4 Slack-2.
                        </p>
                        <h6 className="font-section-sub-header-small">
                          Sharing Content{" "}
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          One of the major perks of{" "}
                          <a href="https://workativ.com/conversational-ai-platform/chatbot-channels">
                            both collaboration tools{" "}
                          </a>
                          is the ability to easily and quickly share content
                          with your peers. This was a notable and exciting
                          feature of Slack when it was first released, but now
                          Teams is a close rival in this category. From within
                          Slack, you can effortlessly create or share Google
                          Drive documents. If your company’s main file
                          collaboration source is Google Drive, then this is an
                          advantageous feature.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          With Teams, you can also easily share files; however,
                          these files are from the Office 365 platform. If your
                          company uses the Office 365 package, then this gives
                          Teams a big edge over Slack. Another interesting
                          feature of sharing content in Teams is that you can
                          view and collaborate on files within Teams. If someone
                          sends you a Word document, you can edit it in Teams
                          without having to leave the platform. However, with
                          Slack, you would need to download the attachment and
                          then edit it externally. Teams-5 Slack-2.
                        </p>
                      </div>

                      <div id="section5">
                        <h6 className="font-section-sub-header-small-bold">
                          Microsoft teams vs Slack bots
                        </h6>
                        <img loading="lazy" src={Section2} className="mb-3" />
                        <p class="font-section-normal-text line-height-2">
                          Both platforms offer a variety of bots via their app
                          directories. Most bot developers see the value in
                          providing their bots for both platforms, and nothing
                          is stopping them from doing so.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          This means you can expect popular bots like Polly and
                          Zapier on both platforms, and integrations through
                          platforms like Trello and SurveyMonkey are of course
                          available on both too.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          The only major difference comes from both platforms'
                          built-in helper bots.{" "}
                          <a href="https://workativ.com/conversational-ai-platform/slack-chatbot">
                            Slackbot
                          </a>{" "}
                          vs WhoBot. You can chat with Slackbot to get answers
                          about how to use Slack or to get custom responses
                          created by owners or administrators. You can create
                          personal reminders and automated responses too.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Microsoft Teams used to offer T-Bot for help with
                          learning Microsoft Teams, but it has since been
                          scrapped for a traditional help system. WhoBot, on the
                          other hand, can be useful for gathering quick data
                          about employees to build new teams or collaborative
                          tasks.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Slackbot certainly gets the crown for this one – if
                          you like automation through custom reminders,
                          automatic responses, and FAQs about Slack or your
                          team, Slackbot is the better choice. Teams-5 Slack-3.
                        </p>
                      </div>

                      <div id="section6">
                        <h6 className="font-section-sub-header-small-bold">
                          Video Conferencing
                        </h6>
                        <img loading="lazy" src={Section3} className="mb-3" />
                        <p class="font-section-normal-text line-height-2">
                          Though Slack is aesthetically pleasing with all the
                          visually appealing features, it’s Microsoft Teams
                          which has a simple and clutter-free interface.
                          Microsoft Teams has a left navigation bar with almost
                          everything you’ll need from the application. One major
                          advantage of Microsoft Teams is the number of people
                          that can join the calls. Microsoft Teams allows up to
                          80 participants to be present on the call, while Slack
                          is limited to only 15 participants. Hence, Slack won’t
                          be suitable for enterprises with large teams that need
                          regular collaboration.
                        </p>
                      </div>

                      <div id="section7">
                        <h6 className="font-section-sub-header-small-bold">
                          More features Teams is slated to receive:{" "}
                        </h6>
                        <ul
                          class="font-section-normal-text"
                          style={{ width: "100%", float: "left" }}
                        >
                          <li class="font-section-normal-text line-height-2">
                            Live captions and subtitles. Allows meeting
                            participants to read speaker captions in real-time.
                            Great for people who have poor hearing or are in
                            loud environments.
                          </li>
                          <li class="font-section-normal-text mb-3 line-height-2">
                            The ability to blur your background on video calls
                            or to apply a custom background. Great for times
                            when other people behind you don’t want to be on
                            camera, your surroundings are busy enough to be
                            distracting, or simply too messy or unprofessional
                            for the nature of your call.
                          </li>
                          <figure class="wp-block-embed-youtube aligncenter wp-block-embed is-type-video is-provider-youtube wp-embed-aspect-16-9 wp-has-aspect-ratio">
                            <div class="wp-block-embed__wrapper ">
                              <div
                                class="rll-youtube-player"
                                data-src="https://www.youtube.com/embed/-wCyq9oll_o"
                                data-id="-wCyq9oll_o"
                                data-query="feature=oembed"
                              >
                                <iframe
                                  src="https://www.youtube.com/embed/-wCyq9oll_o?autoplay=1&amp;feature=oembed"
                                  frameborder="0"
                                  width="100%"
                                  height="500"
                                  allowfullscreen="1"
                                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                ></iframe>
                              </div>
                              <noscript>
                                <iframe
                                  title="Introducing background blur in Microsoft Teams"
                                  src="https://www.youtube.com/embed/-wCyq9oll_o?feature=oembed"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                  allowfullscreen
                                ></iframe>
                              </noscript>
                            </div>
                          </figure>
                          <li class="font-section-normal-text mt-3 line-height-2">
                            Whiteboard masking. When someone is drawing on a
                            whiteboard, Teams can capture the whiteboard and
                            make the presenter’s body translucent so you can see
                            what’s on the whiteboard with them standing in the
                            way, instead of having them block your view of the
                            board.
                          </li>
                        </ul>
                        <h6
                          className="font-section-normal-text
"
                        >
                          Teams-6 Slack-3.
                        </h6>
                      </div>

                      <div id="section8">
                        <h6 className="font-section-sub-header-small-bold">
                          Data Protection
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Beyond the protection measures you can enforce with
                          Microsoft Intune; Teams also provide data loss
                          prevention to stop leaks. Slack does not have a
                          similar service.
                        </p>
                        <p>
                          Both tools provide security measures to encrypt
                          messages, files, and data. They also both come with
                          two-factor authentication. However, Teams has the
                          upper hand again with its admin controls because of
                          its integration with the Microsoft Admin Center
                        </p>
                        <h6
                          className="font-section-normal-text
"
                        >
                          Teams-7 Slack-3.
                        </h6>
                        <h6 className="font-section-sub-header-small-bold">
                          The Winner?
                        </h6>
                        <img loading="lazy" src={Section4} className="mb-3" />
                        <p class="font-section-normal-text line-height-2">
                          Hmm… That’s tough… Because at the end of the day, it
                          all comes down to whether your business already uses
                          Office 365, in which case, hey, come on, would you
                          spend another couple of bucks on Slack when you
                          already have a free similar tool?
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Did you know you can turn your Teams/Slack channel
                          into an internal IT help desk for offering on-the-go
                          self-service for employees/first-line workers? Check
                          out{" "}
                          <a href="https://workativ.com/conversational-ai-platform/slack-chatbot-guide">
                            this
                          </a>{" "}
                          blog on how you can use Workativ Assistant to create
                          AI-powered conversational bots with automated
                          workflows for Workplace IT Support! P.S. It really
                          doesn’t matter if it’s a{" "}
                          <a href="https://workativ.com/conversational-ai-platform/slack-chatbot-guide">
                            Slack Blog,
                          </a>{" "}
                          everything discussed in the blog applies to Teams as
                          well ¯\_(ツ)_/¯
                        </p>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/ms-teams-bot-service-desk-transformation">
                                How MS Teams Bot leads to Service Desk
                                Transformation
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/ms-teams-bot-workplace-support-bot">
                                {" "}
                                MS Teams Bot - Your ultimate workplace support
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/ms-teams-bot-for-hrms">
                                The Future of HRMs is Here with MS Teams Bots
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {isSmall ? null : <OnScrollPopup />} */}

            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>Create Microsft Teams or Slack Chatbot - Get Started</h4>
      <button className="w-35 no_code_button_teams_slack">
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="Conversational AI chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
